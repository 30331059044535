<template>
    <div class="content" style="background: #f1f1f1;" :class="{'hui': resData.ishui == 'Y'}">
        <div class="header-box">
            <div class="header-div">
                <div class="header-top">
                    <img src="static/jr/logo2.png" alt="" class="logo">
                    <p style="display: flex;align-items: center;"><span style="margin: 0px 15px 0 15px;"></span>项目创建</p>
                </div>
            </div>
         </div>
        <div class="top-img">
            <div class="banner-img" v-for="(item,index) in banner" :key="index">
                <img :src="item.img" alt="">
            </div>
        </div>
        <div class="content-1200">
            <div class="select-wrap">
                <div v-for="(val,i) in project" :key="i" style="position:relative;margin-bottom:20px;">
                    <button @click.stop="showClick(i)" :class="showHidden==i?'bag':'select-btn'">{{val.title}}</button>
                    <div v-if="showHidden==i" class="show-wrap">
                        <div v-for="item in val.stypes" :key="item.value" @click.stop="">
                            <p @click="ConBtn(item.tzurl)">{{item.title}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <el-form ref="form" :model="form" label-width="80px">
                <el-select v-for="(val,i) in pricedatas" :key="i" v-model="val.value" :placeholder="val.name">
                    <el-option
                        v-for="item in val.friendList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        @click.native="openUrl(item.url)"
                        >
                    </el-option>
                </el-select>
            </el-form> -->
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Footer from './components/footer'
import JrApi from '../../lvya-common/src/api/eos/jr'
export default {
    components: {
        Footer
    },
    data() {
        return {
            showHidden: -1,
            project: [],
            banner: '',
            form: {
            region: ''
            },
            value: '',
            resData: '',
            pricedatas: [
                {
                    name: '现代化示范校',
                    friendList: [
                        {
                            value: '选项1',
                            label: '黄金糕',
                            url: 'https://www.baidu.com/'
                        },
                        {
                            value: '选项2',
                            label: '黄金糕',
                            url: 'https://www.baidu.com/'
                        }
                    ]
                },
                {
                    name: '省智慧校园',
                    friendList: [
                        {
                            value: '选项1',
                            label: '黄金糕',
                            url: 'https://www.taobao.com/'
                        }
                    ]
                },
                {
                    name: '现代化专业群',
                    friendList: [
                        {
                            value: '选项1',
                            label: '黄金糕',
                            url: 'https://www.taobao.com/'
                        }
                    ]
                },
                {
                    name: '现代化实训基地',
                    friendList: [
                        {
                            value: '选项1',
                            label: '黄金糕',
                            url: 'https://www.taobao.com/'
                        }
                    ]
                },
                {
                    name: '国家改革发展示范校',
                    friendList: [
                        {
                            value: '选项1',
                            label: '黄金糕',
                            url: 'https://www.taobao.com/'
                        }
                    ]
                },
                {
                    name: '高水平现代化职业学校',
                    friendList: [
                        {
                            value: '选项1',
                            label: '黄金糕',
                            url: 'https://www.taobao.com/'
                        }
                    ]
                }
            ]
        }
    },
    created() {
        // this.Banner()
        this.HF()
        this.Project()
        this.getId()
    },
    watch: {
        '$route'(to, from) {
            this.pid = this.$route.query.pid
            this.id = this.$route.query.id
        //    console.log(this.$route.query.pid, this.$route.query.id)
        }
    },
    methods: {
        // openUrl(url) {
        //     window.open(url, '_blank')
        // },
        getId() {
            this.pid = this.$route.query.pid
            this.id = this.$route.query.id
            // console.log(this.pid)
        },
        // 点击页面事件 隐藏需要隐藏的区域
        hiddenClick() {
            this.showHidden = -1
        },
        // 点击列表内，选中内容，并隐藏
        ConBtn(url) {
            // alert('内容' + val)
            this.showHidden = -1
            window.open(url, '_blank')
        },
        showClick(i) {
            this.showHidden = i
        },
        async HF() {
            const resData = await JrApi.HF()
            this.resData = resData
            console.log(resData)
        },
        // banner图
        async Banner() {
            const resData = await JrApi.Banner({ typeid: this.pid })
            this.banner = resData
            if (this.banner == '') {
                const resData1 = await JrApi.Banner()
                this.banner = resData1
            }
            // console.log(this.banner[0].img)
        },
        // async Banner() {
        //     const resData = await JrApi.Banner()
        //     this.banner = resData
        //     // console.log(this.banner)
        // },
        async Project() {
            const resData = await JrApi.Nav({ tagid: 71 })
            this.project = resData
            // console.log(this.project)
        }
    },
    mounted() {
        document.addEventListener('click', this.hiddenClick)
        this.Banner()
    },
}
</script>

<style scope>
@font-face {
 font-family: 'qigong';
 src: url('../../font/qigong.TTF');
}
.hui{
  -webkit-filter:grayscale(100%)
}
.header-box{
    width: 100%;
    background: #fff;
}
.header-div{
    width: 1200px;
    margin: 0 auto;
}
.header-top{
    height: 120px;
    display: flex;
}
.header-top img{
    width: 500px;
    height: 90px;
    margin-top: 10px;
}
.header-top p{
    font-size: 26px;
    color: #ec1d25;
    font-family: qigong;
    font-weight: 600;
}
.header-top p span{
    width: 8px;
    height: 8px;
    background: #ec1d25;
    border-radius: 50%;
    display: inline-block;
    margin: 30px 15px 0 15px;
}
.content {
  width: 100%;
}
.top-img{
  width: 100%;
  height: 410px;
  min-width: 1200px;
}
.banner-img {
    width: 100%;
    height: 410px;
    min-width: 1200px;
    background: url('../../../public/static/jr/banner2.png') center top no-repeat;
    /* background-size: 100% 100%; */
}
.top-img img{
    width: 100%;
    height: 100%;
}
.content-1200{
    width: 1200px;
    min-height: 300px;
    margin: 20px auto;
}
.select-wrap{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.select-btn{
    width: 220px;
    height: 40px;
    border: none;
    background: #fff;
    outline: none;
    margin-right: 20px;
    box-shadow:2px 2px 5px #ccc;
    border-radius: 3px;
    cursor: pointer;
}
.bag{
    width: 220px;
    height: 40px;
    border: none;
    background: #881f26;
    color: #fff;
    outline: none;
    margin-right: 20px;
    box-shadow:2px 2px 5px #ccc;
    border-radius: 3px;
    cursor: pointer;
}
.show-wrap{
    width: 220px;
    min-height: 100px;
    background: #fff;
    position: absolute;
    z-index: 999999;
    /* margin-top: -20px; */
}
.show-wrap p{
    margin-block-start: 0em;
    margin-block-end: 0em;
    line-height: 40px;
    text-align: center;
    color: #444;
    cursor: pointer;
}
</style>
